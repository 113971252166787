import { type Device } from './device'
import { type Image } from './image'

export const BannerTextColor = {
  White: 'white',
  Black: 'black',
} as const

export type BannerTextColor =
  (typeof BannerTextColor)[keyof typeof BannerTextColor]

export type Banner =
  | Record<string, never>
  | {
      textColor: BannerTextColor
      image: Image
      hiddenDevices: Device[]
    }
