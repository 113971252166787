<template>
  <RevButton :class="widthClasses" full-width="adaptive" :size :variant>
    <slot></slot>
  </RevButton>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { tw } from '@backmarket/utils/string/tw'
import { RevButton } from '@ds/components/Button'

type CmsButtonBaseProps = {
  width?: 'adaptive' | 'fixed' | 'full-width'
  size?: InstanceType<typeof RevButton>['$props']['size']
  variant?: InstanceType<typeof RevButton>['$props']['variant']
}

const props = withDefaults(defineProps<CmsButtonBaseProps>(), {
  size: 'medium',
  variant: 'primary',
  width: 'fixed',
})

const widthClasses = computed(() => {
  switch (props.width) {
    case 'fixed':
      return tw`md:!w-[360px] w-full max-w-full`
    case 'full-width':
      return tw`!w-full`
    case 'adaptive':
    default:
      return ''
  }
})
</script>
